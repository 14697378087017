<template>
  <v-content>
    <v-container class="fill-height home-background pa-0" fluid>
      <v-row align="center" justify="center" class="overlay">
        <v-col cols="12" sm="8" md="4">
          <v-stepper v-model="step" outlined class="elevation-5">
            <v-stepper-header class="elevation-0">
              <v-stepper-step color="red darken-4" step="1" :complete="step > 1"
                >Email</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step step="2" color="red darken-4"
                >Phone Number</v-stepper-step
              >

              <v-divider></v-divider>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row no-gutters align="center" justify="center" class="mt-2">
                  <v-col cols="8">
                    <v-text-field
                      v-model="username"
                      color="red darken-4"
                      dense
                      label="Email"
                      outlined
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="password"
                      color="red darken-4"
                      dense
                      label="Password"
                      outlined
                      type="password"
                    />
                  </v-col>
                  <v-col cols="8" class="d-flex align-center justify-center">
                    <v-btn
                      dark
                      :loading="loading"
                      color="red darken-4"
                      @click="onLoginClick"
                    >
                      <template v-slot:loader>
                        <v-progress-circular
                          indeterminate
                          color="white"
                          width="2"
                          size="28"
                        />
                      </template>
                      Send
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row no-gutters align="center" justify="center" class="mt-2">
                  <v-col cols="8">
                    <v-text-field
                      v-model="verificationCode"
                      color="red darken-4"
                      label="Verification code"
                      item-value="verificationCode"
                      :error-messages="
                        requiredError($v.verificationCode, 'Verification Code')
                      "
                      @change="$v.verificationCode.$touch"
                      @blur="$v.verificationCode.$touch"
                      dense
                      outlined
                    />
                  </v-col>
                  <div ref="recaptchaContainer"></div>
                  <v-col cols="8" class="d-flex align-center justify-center">
                    <v-btn
                      color="red darken-4"
                      dark
                      :loading="loading"
                      @click="verifyPhoneNumber"
                    >
                      <template v-slot:loader>
                        <v-progress-circular
                          indeterminate
                          color="white"
                          width="2"
                          size="28"
                        />
                      </template>
                      Send
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="10"
                    class="
                      mt-6
                      cursor-pointer
                      d-flex
                      align-center
                      justify-center
                    "
                    @click="sendPhoneVerificationCode"
                    v-if="elementVisibleOrNot === true"
                  >
                    Click here to send a new Verification code
                  </v-col>
                  <v-col
                    cols="8"
                    class="mt-6 d-flex align-center justify-center"
                  >
                    <span id="timer" class="ml-2"></span>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" top dark color="red">
        <span> {{ errorMessage }} </span>
        <v-btn dark text @click="snackbar = false"> Close </v-btn>
      </v-snackbar>
    </v-container>
  </v-content>
</template>
<script>
import appConfig from '@src/app.config'
import firebaseApp from '../../firebase/app'
import firebase from 'firebase/app'
import 'firebase/auth'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { validateError } from '@utils/validateFields'

export default {
  name: 'Login',
  page: {
    title: 'Log in',meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }],
  },
  mixins: [validationMixin],
  validations() {
    return {
      verificationCode: { required },
    }
  },
  data() {
    return {
      username: '',
      password: '',
      verificationCode: '',
      step: 1,
      snackbar: false,
      authError: null,
      loading: false,
      tryingToLogIn: false,
      resolver: null,
      verificationId: null,
      errorMessage: null,
      loginErrorObject: null,
      elementVisible: false,
      elementVisibleOrNot: false,
      appVerifier: null,
      timerOn: true,
    }
  },
  computed: {
    placeholders() {
      return process.env.NODE_ENV === 'production'
        ? {}
        : {
            username: 'Use "admin" to log in with the mock API',
            password: 'Use "password" to log in with the mock API',
          }
    },
  },
  methods: {
    async onLoginClick() {
      this.loading = true
      try {
        await firebaseApp
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        await firebaseApp
          .auth()
          .signInWithEmailAndPassword(this.username, this.password)
        this.loading = false
        this.$router.push({ name: 'dashboard' })
      } catch (err) {
        this.loading = false
        if (err.code === 'auth/multi-factor-auth-required') {
          this.loginErrorObject = err
          if (err && err.resolver) {
            this.resolver = err.resolver
            this.step = 2
            this.timer(60)
            this.$toastr.success('Verification code sent successfully')
            this.appVerifier = new firebase.auth.RecaptchaVerifier(
              this.$refs.recaptchaContainer,
              {
                size: 'invisible',
              }
            )

            const phoneInfoOptions = {
              multiFactorHint: this.resolver.hints[0],
              session: this.resolver.session,
            }

            var phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
            try {
              this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                this.appVerifier
              )
              this.elementVisible = true
            } catch (err) {
              const { message } = err
              this.errorMessage = message
              this.snackbar = true
            }
          }
        }
      }
    },
    async sendPhoneVerificationCode() {
      const err = this.loginErrorObject
      if (err && err.resolver) {
        this.resolver = err.resolver
        this.step = 2
        this.timer(60)
        this.$toastr.success('New Verification code sent successfully')
        const phoneInfoOptions = {
          multiFactorHint: this.resolver.hints[0],
          session: this.resolver.session,
        }
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
        try {
          this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            this.appVerifier
          )
        } catch (err) {
          const { message } = err
          this.errorMessage = message
          this.snackbar = true
        }
      }
    },
    async verifyPhoneNumber() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (!this.verificationId) {
          throw new Error('Verification id is missing')
        }
        this.loading = true
        const cred = firebase.auth.PhoneAuthProvider.credential(
          this.verificationId,
          this.verificationCode
        )

        const multiFactorAssertion =
          firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
        try {
          await this.resolver.resolveSignIn(multiFactorAssertion)
          this.loading = false
          this.$router.push({ name: 'dashboard' })
        } catch (err) {
          this.loading = false
          const { message } = err
          this.errorMessage = message
          this.snackbar = true
        }
      }
    },
    async timer(remaining) {
      var m = Math.floor(remaining / 60)
      var s = remaining % 60

      m = m < 10 ? '0' + m : m
      s = s < 10 ? '0' + s : s
      document.getElementById('timer').innerHTML = 'Resend OTP : ' + m + ':' + s
      this.elementVisibleOrNot = false
      remaining -= 1

      if (remaining >= 0 && this.timerOn) {
        setTimeout(() => {
          this.timer(remaining)
        }, 1000)
        return
      }

      if (!this.timerOn) {
        return
      }
      this.elementVisible = false
      document.getElementById('timer').innerHTML = ''
      this.elementVisibleOrNot = true
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
  },
}
</script>

<style lang="scss" module>
@import '@design';
.cursor-pointer{
  cursor: pointer;
}
</style>
